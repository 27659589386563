<template>
  <div class="service-form-part">
    <ServiceTypeField
      :choices="serviceTypes"
      @result="setServiceForType($event.value[0])"
      v-on="$listeners"
    />

    <h2 class="form-modal__part-title">
      {{ $t('field.type-of-assignment') }}
    </h2>

    <CustomDimensionFormField :choices="choices" v-on="$listeners" />

    <ServiceField ref="service" hidden v-on="$listeners" />
  </div>
</template>

<script>
import CustomDimensionFormField from 'chimera/all/components/form/fields/customDimension/CustomDimensionSelectableField.vue'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import ServiceTypeField from 'chimera/all/components/form/fields/serviceType/ServiceTypeField'
import { Selectable } from 'chimera/all/components/models/Selectable'
import {
  homeBatteryConsumerNL,
  homeBatteryCorporateNL,
} from 'chimera/homeBatteries/service'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    CustomDimensionFormField,
    ServiceField,
    ServiceTypeField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceTypes: [
        new Selectable(
          this.$i18n.t('field.residential-building').toLowerCase(),
          this.$i18n.t('field.residential-building'),
          homeBatteryConsumerNL.id,
        ),
        new Selectable(
          this.$i18n.t('field.business-premises').toLowerCase(),
          this.$i18n.t('field.business-premises'),
          homeBatteryCorporateNL.id,
        ),
        new Selectable(
          this.$i18n.t('field.agricultural-enterprise').toLowerCase(),
          this.$i18n.t('field.agricultural-enterprise'),
          homeBatteryCorporateNL.id,
        ),
        new Selectable(
          this.$i18n.t('field.large-business-requests').toLowerCase(),
          this.$i18n.t('field.large-business-requests'),
          homeBatteryCorporateNL.id,
        ),
      ],

      choices: [
        new Selectable(
          this.$i18n.t('field.house-battery').toLowerCase(),
          this.$i18n.t('field.house-battery'),
          this.$i18n.t('field.house-battery'),
        ),
        new Selectable(
          this.$i18n.t('field.house-battery-and-solar-panels').toLowerCase(),
          this.$i18n.t('field.house-battery-and-solar-panels'),
          this.$i18n.t('field.house-battery-and-solar-panels'),
        ),
      ],
    }
  },

  methods: {
    /**
     * @param {object}  type
     */
    setServiceForType(type) {
      if (!type) {
        return
      }

      const serviceType = this.serviceTypes.find(
        (serviceType) => serviceType.id === type.id,
      )
      this.$refs.service.setValue(serviceType.value)
    },
  },
}
</script>
